.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .modal-body {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    width: 400px;
    max-width: 100vw;
    height: fit-content;
    max-height: 100vh;

    hr{
      margin-bottom: 10px;
      color: black;
      width: 100%;
    }

    .modal-close {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid black;
      font-weight: 900;
    }
    .modal-content {
      padding: 15px;
    }
  }
}
