.settings-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .settings-header{
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e0e0e0;

        .settings-header-title{
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
    .settings-content{
        width: 100%;
        height: 100%;
    }
}