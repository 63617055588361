.nav-main {
  width: 100vw;
  height: 10vh;
  display: flex;
  background-color: rgba($color: #fff, $alpha: 0.8);
  z-index: 1;

  .logo-container {
    width: calc(20vw - 20px);
    height: 100%;
    padding-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
      text-shadow: 1px 1px 2px #fff;
    }
  }

  .dropdown-container {
    display: none;
  }

  .button-container {
    width: calc(20vw - 20px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    .custom-button {
      margin-left: 20px;
    }
  }

  .navbar-collapse {
    height: 100%;
    .navbar-nav {
      width: calc(75vw - 20px);
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      list-style: none;
      margin: 0;
      margin-left: auto;
      .nav-item {
        padding: 0 20px;
        font-size: 1.3rem;
        font-weight: bold;
        color: #000;
        transition: all 0.3s ease;

        .nav-link {
          color: #000;
          text-decoration: none;
          text-shadow: 1px 1px 2px #fff;
          cursor: pointer;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.logged-in-nav {
  border-bottom: 2px solid black;
  .button-container{
    margin-left: auto;
  }
}

.no-log-in-nav{
  position: absolute;
}

@media (max-width: 768px) {
  .nav-main {
    .logo-container {
      width: unset;
      img {
        height: 50px;
        max-width: unset;
        max-height: unset;
      }
    }
    .dropdown-container {
      display: flex;
      margin-left: auto;
      justify-content: center;
      align-items: center;
      width: 50px;
      padding: 10px 20px;
      cursor: pointer;

      * {
        height: 50px;
        width: 50px;
      }
    }
    .button-container {
      display: none;
      .custom-button {
      }
    }

    .navbar-collapse {
      .navbar-nav {
        height: auto;
        width: fit-content;
        position: absolute;
        top: 10vh;
        right: 5px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 0;
        padding: 0;
        background-color: white;
        border-radius: 0 0 10px 10px;
        padding: 5px;
        .nav-item {
          padding: 10px 0;
          border-bottom: 2px solid black;
          width: 100%;
          margin-bottom: 5px;

          &:hover {
            background-color: #000;
            * {
              color: #fff;
              text-shadow: none;
            }
          }
        }
      }
    }
  }
  .logged-in-nav {
    .button-container{
      display: flex;
    }
  }
}
