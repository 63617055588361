@import "../../../App.scss";

.stats-body{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0;

    .stat-cell{
        height: 100px;
        width: 20%;
        min-width: 100px;
        border-radius: 10px;
        background-color: $light-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: fit-content;

        span{
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}