.template-main{
    display: flex;
    height: 90vh;

    .template-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: scroll;
    }
}