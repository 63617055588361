.login-modal-body{
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:rgba(0, 0, 0, 0.5);
    .login-body{
        width: 350px;
        height: 300px;
        max-width: 98vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        //background color white with opacity
        background-color: rgba(255, 255, 255, 0.7);
    }

}