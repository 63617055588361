@import "../../../App.scss";

.control-panel-main{
    width: 100%;
    height: 100px;
    max-height: 20vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: $med-color 2px solid;

    button{
        line-height: 15px;
    }
}