.gym-signup-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        input{
            margin-bottom: 20px;
            width: 300px;
        }
    }
}