.dashboard-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin-top: -20px;
  }

  table {
    width: 100%;
    max-width: 1100px;
    border-collapse: collapse;
    border: 2px solid black;
    margin-top: 20px;
    margin-bottom: 20px;

    .member-row > td {
      cursor: pointer;
      height: 20px;
    }
    tr:nth-child(even) {
      background-color: lightgray;
    }
    tr:hover {
      background-color: #ddd;
    }
    .past-due {
      background-color: lightcoral !important;
    }
    th {
      border: 2px solid black;
      padding: 10px;
    }
    td {
      border: 2px solid black;
      padding: 10px;
      cursor: pointer;
    }
  }

  .member-modal-row {
    display: flex;
    flex-direction: column;
  }

  .new-member-body {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;

    input {
      margin-bottom: 10px;
      padding: 10px;
    }
  }

  .new-member-body {
    label {
      font-weight: bold;
    }
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .member-modal-row {
    margin-bottom: 10px;

    .member-modal-label {
      font-weight: bold;
    }

    .member-modal-content {
      // margin-bottom: 10px;
      // padding: 10px;
    }
  }
  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}
