@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles";

.calendar-container{
    width: 100%;
    height: 78vh;
    margin: 20px auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    margin-bottom: 20px;
    overflow: scroll;
}