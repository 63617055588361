.info-modal-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .info-modal-body{
        width: 500px;
        height: 500px;
        max-width: 95vw;
        max-height: 95vh;
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        

        .info-modal-close{
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem;
            cursor: pointer;
        }
    }
}