@import "../../../App.scss";

.sidebar-main {
  height: calc(90vh - 2px);
  width: 100px;
  max-width: 20vw;
  min-width: 100px;
  border-right: $med-color 1px solid;
  // border-top: $med-color 1px solid;
  // border-radius: 0 30px 0 0;
  // padding-top: 50px;
  display: flex;
  flex-direction: column;
  background-color: $light-color;
  z-index: 1;

  .sidebar-links {
    display: flex;
    flex-direction: column;

    &:first-child {
      img {
        padding-top: 50px;
      }
    }
    a {
      height: 100px;
      text-decoration: none;
      color: black;
      border-bottom: 1px solid $med-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;

      img, svg {
        width: 50px;
        height: 50px;
        max-width: 50px;
        max-height: 50px;
      }

      &:hover {
        // color: white;
        background-color: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    height: 6vh;
    width: 100vw;
    max-width: none;
    min-width: none;
    border-top: $med-color 1px solid;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    padding-bottom: 4vh;
    .sidebar-links {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      height: 100%;

      &:first-child {
        img {
          padding-top: 0;
        }
      }

      a {
        padding: 0;
        height: 100%;

        img, svg {
          height: 30px;
          width: auto;
          padding-top: 0;
        }
      }
    }
  }
}
