$dark-color: #000;
$med-color: #333;
$light-color: lightgray;
$background: #fff;

.App {
  display: flex;
  flex-direction: column;
}

.custom-button {
  background: none;
  color: #ffffff;
  font-size: 19px;
  border: 5px solid #ffffff;
  border-radius: 10px;
  padding: 15px 15px;
  cursor: pointer;
  font-weight: 800;

  &:hover {
    background: #ffffff;
    color: #000000;
  }

  &-primary {
    @extend .custom-button;
    color: #000000;
    border-color: #000000;
    border-width: 3px;
    // text-shadow: 1px 1px 2px #fff;

    &:hover {
      background-color: #000;
      color: #ffffff;
    }
    &:active {
      box-shadow: 0 2px black; /* smaller shadow to simulate button press */
    }
  }
  &-tertiary{
    @extend .custom-button;
    color: #000000;
    border-color: #fff;
    border-width: 3px;
    background-color: #fff;

    &:hover {
      background-color: #000;
      color: #ffffff;
    }
    &:active {
      box-shadow: 0 2px black; /* smaller shadow to simulate button press */
    }
  
  }
}

.homepage-main {
  width: 100vw;
  height: 100vh;
  background-image:linear-gradient(to top, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, 1) 90%), url("./Images/newBackground.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 70%, black 100%);
  }

  .cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      color: white;
      text-shadow: 2px 2px 4px #000000;
      font-size: 6rem;
      text-align: center;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 3rem;
      }
    }
    .background-img {
      width: 100%;
    }

    // @media (max-width: 768px) {
    //   background-image: url("./Images/mobileHomepageNew.jpg");
    //   background-position: 40%;
    // }

    .signup-button-container {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding-top: 60px;

      .signup-button {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background: linear-gradient(
          to bottom,
          #ffa500,
          #ff4500
        ); /* orange gradient */
        border: none;
        border-radius: 5px; /* rounded corners */
        box-shadow: 0 4px #c14000; /* shadow to create 3D effect */
        cursor: pointer;
        transition: all 0.3s ease; /* smooth transition for hover effects */
        text-transform: uppercase; /* optional: makes text uppercase */
        outline: none; /* removes the outline */
        user-select: none; /* prevents text selection */
      }

      /* Button hover state */
      .signup-button:hover {
        background: linear-gradient(
          to bottom,
          #ffc107,
          #ff8c00
        ); /* brighter orange gradient */
        box-shadow: 0 6px #b23000; /* larger shadow for lifted effect */
        transform: translateY(
          -2px
        ); /* slight move up to simulate button press */
      }

      /* Button active state (when clicked) */
      .signup-button:active {
        box-shadow: 0 2px #b23000; /* smaller shadow to simulate button press */
        transform: translateY(4px); /* moves button down when clicked */
      }
    }
  }
}

.features{
  min-height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(to bottom,rgba(0,0,0,1) 0%, rgba(0,0,0,1) 90%,rgba(0,0,0,0.5)100%);
  background-color: #000;
  flex-wrap: wrap;
  @media screen and (max-width: 768px){
    padding-top: 30px;
  }
  .card{
    width: 30vw;
    max-width: 300px;
    min-height: 50vh;
    padding: 20px;
    background-color: #fff;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #000;
    
    @media screen and (max-width: 768px){
      width: 80vw;
      height: fit-content;
      max-width: 100vw;
      min-height: unset;
    }

    h2{
      color: #000;
      font-size: 2rem;
      margin-bottom: 20px;
    }
    p{
      color: #000;
      font-size: 1.5rem;
      text-align: center;
    }
  }
}

.about{
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(to top, transparent 90%, rgba(0,0,0,0.5)100%);
  background-color: #000;

  .about-container{
    width: 80vw;
    max-width: 800px;
    min-height: 50vh;
    padding: 20px;
    background-color: #fff;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #000;

    h1{
      color: #000;
      font-size: 2rem;
      margin-bottom: 20px;
    }
    p{
      color: #000;
      font-size: 1.5rem;
      text-align: center;
    }
  }
}