.schedule-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .schedule-modal-body {
    width: 500px;
    height: fit-content;
    min-height: 500px;
    max-height: 90vh;
    max-width: 90vw;
    background-color: white;
    z-index: 100;
    border-radius: 10px;
    padding: 30px;

    .schedule-modal-body-content-inputs{
        .schedule-modal-body-content-inputs-input{
            width: 100%;
            padding: 10px 0px;
            display: flex;
            flex-direction: column;
            align-items: start;

            textarea{
                height: 100px;
                resize: none;
            }
            *{
              width: 100%;
            }
            input[type="checkbox"]{
              width: auto;
            }
        }
    }

    .schedule-modal-body-content-buttons{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
  }
}
